/**
 * Add / edit survey.
 */
import axios from 'axios';
import { apiDelete, apiGet, apiPost, apiPut } from '@/util/api';
import Icon from '@/icons/Icon.vue';

export default {
  name: 'Survey',
  components: {
    Icon,
  },
  data() {
    return {
      loading: true,
      survey: { name: '' },
      countries: [],
      countriesTableFields: [
        {
          key: 'name',
          label: this.$t('survey_list_survey_name'),
        },
        {
          key: 'editPanel',
          label: '',
        },
        {
          key: 'edit',
          label: '',
          class: 'table-user-control',
        },
        {
          key: 'delete',
          label: '',
          class: 'table-user-control',
        },
      ],
      perPage: 10,
      currentPage: 1,
      deleteCountry: { name: '' },
      newCountryVisible: false,
      newCountry: {
        name: '',
        nameState: null,
        fileCsv: null, // Survey country csv file - weights (optional)
        fileCsvState: null,
        fileJson: null, // Survey country json file - synonyms
        fileJsonState: null,
        fileTxt: null, // Survey country txt file - survey responses
        fileTxtState: null,
        fileXml: null, // Survey country xml file
        fileXmlState: null,
      },
      editCountry: {
        id: null,
        fileCsv: null, // Survey country csv file - weights (optional)
        fileCsvState: null,
        fileJson: null, // Survey country json file - synonyms
        fileJsonState: null,
        fileTxt: null, // Survey country txt file - survey responses
        fileTxtState: null,
        fileXml: null, // Survey country xml file
        fileXmlState: null,
      },
    };
  },
  async created() {
    // Get the data required for the page.
    await Promise.all([this.getSurvey(), this.getCountries()]);
    this.loading = false;
  },
  computed: {
    /**
     * Determine whether the new country form has valid inputs
     */
    newCountryValid() {
      return (
        this.newCountry.nameState &&
        (this.newCountry.fileCsvState ||
          this.newCountry.fileCsvState === null) && // optional
        this.newCountry.fileJsonState &&
        this.newCountry.fileTxtState &&
        this.newCountry.fileXmlState
      );
    },

    /**
     * Determine whether the edit country form has valid inputs.
     * All are optional, but at least one must be provided.
     */
    editCountryValid() {
      return (
        this.editCountry.id &&
        (this.editCountry.fileCsvState ||
          this.editCountry.fileJsonState ||
          this.editCountry.fileTxtState ||
          this.editCountry.fileXmlState) &&
        (this.editCountry.fileCsvState ||
          this.editCountry.fileCsvState === null) &&
        (this.editCountry.fileJsonState ||
          this.editCountry.fileJsonState === null) &&
        (this.editCountry.fileTxtState ||
          this.editCountry.fileTxtState === null) &&
        (this.editCountry.fileXmlState ||
          this.editCountry.fileXmlState === null)
      );
    },
  },
  methods: {
    /**
     * Get the specified client.
     */
    async getSurvey() {
      await apiGet(`/survey/${this.$route.params.surveyId}`)
        .then(survey => {
          this.survey = survey;
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('survey_error_get_survey'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });
    },

    /**
     * Get the full list of country tags.
     */
    async getCountries() {
      await apiGet('/tag?category=COUNTRY')
        .then(countries => {
          this.countries = countries.sort(this.sortByName);
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('survey_error_get_countries'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });
    },

    /**
     * Sort objects (countries) by name
     * @param {Object} c1
     * @param {Object} c2
     */
    sortByName(c1, c2) {
      return c1.name === c2.name ? 0 : c1.name < c2.name ? -1 : 1;
    },

    /**
     * Check the validity of a new country input.
     */
    checkInputValid(ref) {
      if (ref === 'name') {
        this.newCountry.nameState = this.$refs['name'].checkValidity();

        // New country name must be unique
        const existingCountry = this.countries.find(
          c => c.name.toLowerCase() === this.newCountry.name.toLowerCase(),
        );
        if (existingCountry) {
          this.newCountry.nameState = false;
        }
      } else {
        const input = this.$refs[ref].$el.querySelector('input');
        this.newCountry[`${ref}State`] = input.checkValidity();
        if (this.newCountry[ref]) {
          const parts = this.newCountry[ref].name.split('.');
          const ext = parts.length ? parts[parts.length - 1] : '';
          if (ext.toLowerCase() !== input.accept.toLowerCase()) {
            this.newCountry[`${ref}State`] = false;
          }
        }
      }
    },

    /**
     * Check the validity of a new country input.
     * Note - this code is very similar to the second part of checkInputValid above. If one changes, the other
     * probably needs to as well.
     */
    checkEditInputValid(ref) {
      const input = this.$refs[ref].$el.querySelector('input');
      this.editCountry[`${ref}State`] = input.checkValidity();
      if (this.editCountry[ref]) {
        const parts = this.editCountry[ref].name.split('.');
        const ext = parts.length ? parts[parts.length - 1] : '';
        if (ext.toLowerCase() !== input.accept.toLowerCase()) {
          this.editCountry[`${ref}State`] = false;
        }
      }
    },

    /**
     * Add a country to the survey.
     */
    async addCountry() {
      if (!this.newCountryValid) {
        return;
      }

      this.loading = true;

      // Generate country data
      const newCountryData = {
        name: this.newCountry.name,
        hasCsv: !!this.newCountry.fileCsv,
      };

      // Submit the new country data
      await apiPost(
        `/survey/${this.$route.params.surveyId}/country`,
        newCountryData,
      )
        .then(async responseData => {
          const fileUploads = [
            axios.put(responseData.jsonUrl, this.newCountry.fileJson),
            axios.put(responseData.txtUrl, this.newCountry.fileTxt),
            axios.put(responseData.xmlUrl, this.newCountry.fileXml),
          ];
          if (newCountryData.hasCsv) {
            fileUploads.push(
              axios.put(responseData.csvUrl, this.newCountry.fileCsv),
            );
          }
          await Promise.all(fileUploads).catch(() => {
            this.$bvToast.toast(
              this.$t('survey_error_create_country_s3_upload', {
                countryName: newCountryData.name,
              }),
              {
                title: this.$t('common_error_title'),
                variant: 'danger',
              },
            );
          });

          // Add the country to the countries list and sort the list
          this.countries.push({
            id: responseData.id,
            name: this.newCountry.name,
          });
          this.countries.sort(this.sortByName);

          // Show success message
          this.$bvToast.toast(
            this.$t('survey_success_create_country', {
              countryName: newCountryData.name,
            }),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('survey_error_create_country', {
              countryName: newCountryData.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        });

      // Clear up
      this.closeAddNewCountry();
      this.loading = false;
    },

    /**
     * Update the survey data for a country.
     */
    async doEditCountry() {
      if (!this.editCountryValid) {
        return;
      }

      this.loading = true;

      const editingCountry = this.countries.find(
        c => c.id === this.editCountry.id,
      );

      // Update the files for the country.
      await apiPut(
        // Get signed S3 URLs for putting the new data to.
        `/survey/${this.$route.params.surveyId}/country/${this.editCountry.id}`,
      )
        .then(async responseData => {
          const fileUploads = [];

          if (this.editCountry.fileCsv && this.editCountry.fileCsvState) {
            fileUploads.push(
              axios.put(responseData.csvUrl, this.editCountry.fileCsv),
            );
          }
          if (this.editCountry.fileJson && this.editCountry.fileJsonState) {
            fileUploads.push(
              axios.put(responseData.jsonUrl, this.editCountry.fileJson),
            );
          }
          if (this.editCountry.fileTxt && this.editCountry.fileTxtState) {
            fileUploads.push(
              axios.put(responseData.txtUrl, this.editCountry.fileTxt),
            );
          }
          if (this.editCountry.fileXml && this.editCountry.fileXmlState) {
            fileUploads.push(
              axios.put(responseData.xmlUrl, this.editCountry.fileXml),
            );
          }

          await Promise.all(fileUploads)
            .then(() => {
              this.$bvToast.toast(
                this.$t('survey_success_edit_country', {
                  countryName: editingCountry.name,
                }),
                {
                  title: this.$t('common_success_title'),
                  variant: 'success',
                },
              );
            })
            .catch(() => {
              this.$bvToast.toast(
                this.$t('survey_error_edit_country_s3_upload', {
                  countryName: editingCountry.name,
                }),
                {
                  title: this.$t('common_error_title'),
                  variant: 'danger',
                },
              );
            });
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('survey_error_edit_country', {
              countryName: editingCountry.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        });

      // Clear up
      this.resetEditCountry();
      this.loading = false;
    },

    /**
     * Cancel adding a new country.
     */
    closeAddNewCountry() {
      this.newCountryVisible = false;
      this.resetNewCountry();
    },

    /**
     * Reset new country data.
     */
    resetNewCountry() {
      this.newCountry = {
        name: '',
        nameState: null,
        fileCsv: null, // Survey country csv file - weights (optional)
        fileCsvState: null,
        fileJson: null, // Survey country json file - synonyms
        fileJsonState: null,
        fileTxt: null, // Survey country txt file - survey responses
        fileTxtState: null,
        fileXml: null, // Survey country xml file
        fileXmlState: null,
      };
    },

    /**
     * Reset edit country data.
     */
    resetEditCountry() {
      this.editCountry = {
        id: null,
        fileCsv: null, // Survey country csv file - weights (optional)
        fileCsvState: null,
        fileJson: null, // Survey country json file - synonyms
        fileJsonState: null,
        fileTxt: null, // Survey country txt file - survey responses
        fileTxtState: null,
        fileXml: null, // Survey country xml file
        fileXmlState: null,
      };
    },

    /**
     * Open the edit panel for a country.
     * @param {number} countryId
     */
    openCountryEdit(countryId) {
      this.editCountry.id = countryId;
    },

    /**
     * Confirm that the user really wants to delete the specified country.
     * @param countryId
     */
    confirmDeleteCountry(countryId) {
      this.deleteCountry = this.countries.find(c => c.id === countryId);
      this.$bvModal.show('country-confirm-delete');
    },

    /**
     * Proceed with deleting the specified country.
     */
    async doDeleteCountry() {
      this.loading = true;
      await apiDelete(
        `/survey/${this.$route.params.surveyId}/country/${this.deleteCountry.id}`,
      )
        .then(() => {
          // Remove the country from the list
          const countryIndex = this.countries.findIndex(
            c => c.id === this.deleteCountry.id,
          );
          this.countries.splice(countryIndex, 1);
          this.$bvToast.toast(
            this.$t('survey_success_delete_country', {
              countryName: this.deleteCountry.name,
            }),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('survey_error_delete_country', {
              countryName: this.deleteCountry.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
